import React from "react";
import { Helmet } from "react-helmet";
import config from "../../config";

export default ({
    metaTitle,
    metaDescription,
    lang,
    nonLocalizedSlug,
    siteUrl,
}) => {
    const getUrl = (nonLocalizedSlug, lang, siteUrl) => {
        let slug = "";
        if (
            !(
                nonLocalizedSlug === "/" ||
                !nonLocalizedSlug ||
                nonLocalizedSlug.length === 0
            )
        ) {
            deflang += "/" + nonLocalizedSlug;
            slug = nonLocalizedSlug;
        }
        return lang === config.defaultLanguage
            ? slug === ""
                ? siteUrl
                : siteUrl + "/" + slug
            : slug === ""
            ? siteUrl + "/" + lang
            : siteUrl + "/" + lang + "/" + slug;
    };
    let deflang = siteUrl;

    return (
        <Helmet htmlAttributes={{ lang: lang }}>
            {metaTitle ? <title>{metaTitle}</title> : null}
            {metaTitle ? <meta name="title" content={metaTitle} /> : null}
            {metaDescription ? (
                <meta name="description" content={metaDescription} />
            ) : null}
            {metaTitle ? (
                <meta property="og:title" content={metaTitle} />
            ) : null}
            {metaDescription ? (
                <meta property="og:description" content={metaDescription} />
            ) : null}
            {metaTitle ? (
                <meta property="twitter:title" content={metaTitle} />
            ) : null}
            {metaDescription ? (
                <meta
                    property="twitter:description"
                    content={metaDescription}
                />
            ) : null}
            <link
                rel="canonical"
                href={getUrl(nonLocalizedSlug, lang, siteUrl)}
            />
            <link rel="alternate" href={deflang} hrefLang="x-default" />
            {config.languages.map(lang => {
                return (
                    <link
                        rel="alternate"
                        hrefLang={lang.code}
                        href={getUrl(nonLocalizedSlug, lang.code, siteUrl)}
                    />
                );
            })}
        </Helmet>
    );
};
